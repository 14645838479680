.link_section{
	margin-top: 25px;
	text-decoration: none;
}
.link_button{
	color:rgb(100, 255, 218);
	border: 1px solid rgb(100, 255, 218);
	border-radius: 5px;
	padding: 0.75rem 1.15rem;
	font-size: 14px;
	line-height: 1;
	text-decoration: none;
	cursor: pointer;
	margin-top: 25px;
	transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.link_button:hover{
	background-color: #132439;
	color:rgb(100, 255, 218);
	text-decoration: none;
}

.link{
	padding-bottom: 35px;
}
