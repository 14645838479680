.tags {
  list-style: none;
  margin: 0;
  overflow: hidden; 
  padding: 0;
}

.tags li {
  float: left; 
}

.tag {
  background: rgb(136,146,176);
  border-radius: 3px 0 0 3px;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
  cursor: pointer;
  font-size: 15px;
  color: rgb(204, 214, 246)!important;
}

.tag::before {
  background: #0a192f;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: '';
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.tag::after {
  background: #0a192f;
  border-bottom: 13px solid transparent;
  border-left: 10px solid rgb(136,146,176);
  border-top: 13px solid transparent;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
}

.tag:hover {
  color: rgb(100, 255, 218)!important;
 
}