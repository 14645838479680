.works_section{
	margin: 0px auto;
	padding: 75px 0px;
	position: relative;
}

.project{
	padding: 60px 0px;
}

.works_title{
	position: relative;
	display: flex;
	align-items: center;
	margin: 10px 0px 40px;
	white-space: nowrap;
	font-size: 32px;
	color: rgb(204, 214, 246);
}
.works_section h3:after { 
    content: ""; 
    flex: 1 1; 
    border-bottom: 1px solid rgb(48,60,85); 
    margin-left: 20px;
    max-width: 300px; 
} 

.project_title{
	color:rgb(204, 214, 246);
}

.project_info{
	padding: 0px 55px;
}

@media(max-width: 1024px){
	.works_section{
		padding-left: 10px;
	}
}


.work_thumbnail{
	width: 100%;
	height:430px;
	margin-top: 30px;
	border: 1px solid white;
}
.work_thumbnail:hover{
	box-shadow:7px 7px 5px rgb(100, 255, 218);
    transition: all 0.2s ease-in-out;
}

.project_description{
	padding: 15px 10px;
	max-width: 500px;
}

.paragraph_link{
	text-decoration: none;
	color: rgb(100, 255, 218);
}
.paragraph_link:hover{
	text-decoration: none;
	color: rgb(100, 255, 218);
}

.myworks_outro{
	padding:25px;
	text-align: center;
}

@media(max-width: 756px){
	.works_section h3:after{
		max-width: 200px;
		margin-left: 10px;
	}
	.project_info{
		padding-top: 35px;
	}
	.work_thumbnail{
		height: 350px;
	}
}

/*  Reording divs for mobile */
@media only screen and (max-width : 991px) {
  .project_thumbnail {
    -webkit-order: 1;
    order: 1;
  }

  .project_info {
    -webkit-order: 2;
    order: 2;
    padding-top: 35px;
  }
  .work_thumbnail{
  	width: 90%;
  }
  
}

